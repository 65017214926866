<template>
  <div class="content-box-padding">
    <div class="line-text">
      研究院依托南京大学大气与地球系统科学（教育部）国际合作联合实验室，凝聚南京大学大气污染防治和绿色发展方向优势团队，旨在推动相关成果的转化与落地应用，组建一支可以支撑和服务于国家和地方大气复合污染防治和绿色发展的专业团队，建成一个面向相关行业部门业务需求的专业技术人才培养以及国内外相关环保产业之间合作与交流的平台。<br />
      研究院通过平台与当地政府、国内外人才专家建立良好的交流机制，未来还将通过汇聚优秀专业人才，打造良好产业生态，不断完善并促进高校相关学科发展。
    </div>
    <img
      src="~assets\image\recruit\合作交流1.png"
      alt="合作交流1"
      style="margin: 40px auto 10px auto"
    />
    <div class="img-title">研究院定向捐赠防疫物资，助力浦口区抗击疫情</div>

    <img
      src="~assets\image\recruit\合作交流2.png"
      alt="合作交流2"
      style="margin: 40px auto 10px auto"
    />
    <div class="img-title">
      研究院创新成果亮相浦口区科技创新大会，负责人荣获突出贡献先进个人
    </div>
  </div>
</template>
